<template>
    <div class="container">
        <div class="header"/>
        <div class="card overflow-auto">
        <Toast />

            <router-view />
        </div>
        <div class="footer"/>
    </div>
</template>
<script>

export default {
    components: {
    },
    methods: {

    }
};
</script>
<style scoped lang="scss">
:deep(::-webkit-scrollbar-track) {
    background-color: #F4F4F4;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    background: #F4F4F4;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
}
.container {
    background-color: #eaeaea;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    background-color: #EF2595;
    position: absolute;
    top: 0;
    left: 0;
    height: 5vh;
    width: 100vw;
}

.card {
    border-radius: 15px;
    background-color: #ffffff;
    height: 85vh;
    width: 95vw;
}

.footer {
    background-color: #EF2595;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5vh;
    width: 100vw;
}
</style>